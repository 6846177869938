import { Ask } from "./Ask";

export const AskBook = () => {
  return (
    <Ask
      pills={[
        "JSX",
        "Fiber Reconciliation",
        "Server Components",
        "useDeferredValue",
      ]}
      id="ask-book"
      placeholders={[
        "Why does everyone hate useEffect?",
        "What is the role of the bundler with server components?",
        "What is React Forget?",
        "Should I use a framework?",
        "Why is React a thing?",
      ]}
      apiRoute="askBook"
      stream
    />
  );
};
